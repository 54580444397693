<template>
  <div class="mt-5">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" offset-md="3">
          <v-snackbar :timeout="3000" v-model="snackbar">
            {{ textMsg }}
          </v-snackbar>
          <div>Delete My Account</div>
          <p>Please let us know why your account should be deleted</p>
          <v-form lazy-validation ref="deleteAccount" class="mt-4">
            <v-text-field
              placeholder="Enter your email address"
              v-model="form.email"
              :rules="helpers.requiredFormFields('email')"
              outlined
              type="email"
              class="my-1"
            ></v-text-field>
            <v-textarea
              class="my-1"
              outlined
              v-model="form.reason"
              placeholder="Please share why you want to delete your account (Optional)"
            ></v-textarea>
            <v-btn
              color="error"
              @click="$refs.deleteAccount.validate() ? deleteAccount() : null"
              :loading="loading"
              block
            >
              Submit
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Helpers from "@/utils/helpers";
export default {
  name: "deleteAccount",
  data() {
    return {
      helpers: new Helpers(),
      loading: false,
      textMsg: "",
      form: {},
      snackbar: false,
    };
  },
  methods: {
    async deleteAccount() {
      this.loading = true;
      try {
        this.textMsg = "Your request has been received and is being processed";
        this.loading = false;
        this.snackbar = true;
        this.$refs.deleteAccount.reset();
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
